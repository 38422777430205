import React, { useEffect, useState } from "react"
import Layout from "../components/_App/layout"
import SEO from "../components/_App/seo"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Footer from "../components/_App/Footer"
import NavbarNew from "../components/NavbarNew"
import homeBanner from "../assets/images/home-banner2.jpg"
import { Link, graphql } from "gatsby"
import * as Icon from "react-feather"
import Rectangle from "../assets/images/bigdata-analytics/rectangle.png"
import RecipeList from "../components/recipe-list"
import CaseStudyList from "../components/case-study-list"
import Testimonial from "../components/testimonial"
import Loadable from "@loadable/component"
import GetInTouch from "../components/get-in-touch"
const OwlCarousel = Loadable(() => import("react-owl-carousel3"))
import webAppIcon from "../assets/images/web-app-service-icon.png"
import mobileAppIcon from "../assets/images/mobile-app-service-icon.png"
import link from "../assets/images/link.png"
import ScheduleMeetingDialog from "../components/ScheduleMeetingDialog.js"

const IndexPage = ({ data }: any) => {
  const [showCalendlyWidget, setShowCalendlyWidget] = useState(false)

  const caseStudyList = data?.caseStudies?.nodes.filter(
    (node: any) => node.relativeDirectory.search("/") === -1
  )
  const featuredCaseStudy =
    data.featuredCaseStudy.siteMetadata.homeFeaturedCaseStudies

  const filteredCaseStudyList = featuredCaseStudy.reduce(
    (acc: any, cur: any) => {
      const studyListItem = caseStudyList.find(
        (item: any) => item.relativeDirectory === cur
      )
      if (studyListItem) {
        acc.push(studyListItem)
      }
      return acc
    },
    []
  )
  // console.log("filteredCaseStudyList", filteredCaseStudyList)

  // console.log("caseStudyList", caseStudyList)

  const testimonials = data?.testimonials?.nodes.filter(
    (node: any) => node.relativeDirectory.search("/") === -1
  )
  // console.log("testimonials", testimonials)

  const awards = data?.awards?.nodes.filter(
    (node: any) => node.relativeDirectory.search("/") === -1
  )
  // console.log("awards", awards)

  const testimonialCarouselOptions = {
    loop: true,
    nav: true,
    dots: true,
    autoplay: true,
    smartSpeed: 1000,
    autoplayTimeout: 7000,
    items: 1,
    navClass: ["owl-pre-icon", "owl-next-icon"],
    navText: ["", ""],
  }

  const clients = data?.clients?.nodes.filter(
    (node: any) => node.relativeDirectory.search("/") === -1
  )
  const clientsCarouselOptions = {
    loop: true,
    nav: true,
    dots: true,
    autoplay: false,
    smartSpeed: 1000,
    autoplayTimeout: 5000,
    items: 3,
    margin: 30,
    navClass: ["owl-pre-icon", "owl-next-icon"],
    navText: ["", ""],
    responsive: {
      // breakpoint from 0 up
      0: {
        items: 1,
      },
      // breakpoint from 480 up
      // 480 : {
      // items: 1,
      // },
      // breakpoint from 768 up
      768: {
        items: 2,
      },
      1200: {
        items: 3,
      },
    },
  }
  const awardsCarouselOptions = {
    loop: true,
    nav: true,
    dots: true,
    autoplay: true,
    smartSpeed: 1000,
    autoplayTimeout: 5000,
    items: 1,
    navClass: ["owl-pre-icon", "owl-next-icon"],
    margin: 30,
    navText: ["", ""],
  }

  const recipeFeatures = data?.ourRecipeFeatures?.nodes

  return (
    <Layout>
      {showCalendlyWidget && (
        <ScheduleMeetingDialog
          show={showCalendlyWidget}
          hide={setShowCalendlyWidget}
        />
      )}
      <SEO
        title="Tarkashilpa - Web and Mobile Application Development Company"
        description="Tarkashilpa is a web and mobile application development company with knowledge of latest technologies and innovation. We follow strict app development standards."
        keywords="Web Application Development Services Company Mobile Application Development Services Company"
        url={``}
        ogTwitterCardType="summary"
      />
      <NavbarNew />
      <main>
        <div className="home-banner">
          <div className="container">
            <div className="home-banner-content-wrapper">
              <div className="home-banner-content">
                <h1>Apps that deliver</h1>
                <h2>
                  Looking to build an app that delivers desired business
                  outcomes and more? We have a recipe to build one ...
                </h2>
                <p className="action">
                  <a
                    className="btn btn-primary"
                    onClick={() => {
                      setShowCalendlyWidget(true)
                    }}
                  >
                    Schedule a Meeting
                  </a>
                  <a className="btn btn-primary" href="#case-studies">
                    Explore our Work
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="banner-image">
            <div className="overlay"></div>
            <figure className="d-none">
              <img src={homeBanner} alt="Tarkashilpa" />
            </figure>
          </div>
        </div>

        <section className="services ptb-80 bg-eef6fd" id="services">
          <div className="container">
            <div className="section-title">
              <h2 className="hover-link">
                <span>
                  <a href="#services">
                    <img src={link} alt="" />
                  </a>
                </span>
                Services
              </h2>
              <div className="bar"></div>
            </div>

            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="single-services-box-item">
                  <div className="icon backgroundNone">
                    <img src={webAppIcon} alt="image" />
                  </div>
                  <h3>
                    <Link to="/services/web-app-development/">
                      Web App Development
                    </Link>
                  </h3>
                  <p>
                    {" "}
                    A web-based solution can automate your business processes
                    and help your team to work efficiently. It brings effective
                    collaboration and let your team work in synergy. It enables
                    easy tracking of your business KRA and drives powerful
                    insights from the business data. A well-designed solution
                    would boost your brand’s engagement with your customers and
                    can drastically improve the conversion rate.{" "}
                  </p>

                  <Link
                    to="/services/web-app-development/"
                    className="learn-more-btn"
                  >
                    <Icon.ArrowRight /> Learn More
                  </Link>

                  <div className="shape">
                    <img src={Rectangle} alt="image" />
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="single-services-box-item">
                  <div className="icon backgroundNone">
                    <img src={mobileAppIcon} alt="image" />
                  </div>
                  <h3>
                    <Link to="/services/mobile-app-development/">
                      Mobile App Development
                    </Link>
                  </h3>
                  <p>
                    A mobile app can forge a very intimate connection between
                    your brand and customers. It opens up a world of
                    possibilities for customer engagement. A mobile app has the
                    advantage of availability, your customers can engage while
                    they are traveling or away from their workstations. You can
                    leverage this advantage to reach your customers at the time
                    when they are in the right state of mind to engage.
                  </p>

                  <Link
                    to="/services/mobile-app-development/"
                    className="learn-more-btn"
                  >
                    <Icon.ArrowRight /> Learn More
                  </Link>

                  <div className="shape">
                    <img src={Rectangle} alt="image" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="our-recipe ptb-80" id="our-recipe">
          <div className="container">
            <div className="section-title">
              <h2 className="hover-link">
                <span>
                  <a href="#our-recipe">
                    <img src={link} alt="" />
                  </a>
                  Our Recipe
                </span>
              </h2>
              <div className="bar"></div>
              <p>
                Our recipe to deliver winning apps is the perfect blend of
                processes, practices, and efficient tools combined with people
                of the right skill set. We groom our team with a favorable
                culture that encourages them to understand fundamentals and
                appreciate the value they provide to businesses. Below mentioned
                are the guiding principles of our recipe. Our full recipe is
                available <a href="/our-recipe">here</a>
              </p>
            </div>
            {recipeFeatures &&
              recipeFeatures.length > 0 &&
              recipeFeatures.map((list, index) => {
                const type = index % 2 === 0 ? "odd" : "even"
                console.log("type", type)
                return (
                  <RecipeList
                    title={list.childMdx.frontmatter.title}
                    content={<MDXRenderer>{list?.childMdx?.body}</MDXRenderer>}
                    thumb={list.childMdx.frontmatter.thumbImg.publicURL}
                    type={type}
                  />
                )
              })}

            <div className="text-align-center">
              <a className="btn btn-primary" href="/our-recipe">
                Learn More
              </a>
            </div>
          </div>
        </section>

        <section className="case-studies ptb-80 bg-eef6fd" id="case-studies">
          <div className="container">
            <div className="section-title">
              <h2 className="hover-link">
                <span>
                  <a href="#case-studies">
                    <img src={link} alt="" />
                  </a>
                  Case Studies
                </span>
              </h2>

              <div className="bar"></div>
              <p>
                We have delivered the success to businesses who are market
                leaders of their industries through our time tested
                methodologies and values. These case studies demonstrate the
                effectivity <a href="#our-recipe">our recipe</a> and
                capabilities.
              </p>
            </div>
            {filteredCaseStudyList && filteredCaseStudyList.length > 0 && (
              <div className="d-flex flex-md-row flex-column justify-content-center gap-3">
                {filteredCaseStudyList.map((list, index) => (
                  <div className="flex-1 single-solutions-box" key={index + 1}>
                    <CaseStudyList data={list?.childMdx} />
                  </div>
                ))}
              </div>
            )}
            <div className="text-align-center">
              <a className="btn btn-primary" href="/our-work">
                View More
              </a>
            </div>
          </div>
        </section>

        <section className="testimonial ptb-80" id="testimonial">
          <div className="container">
            <div className="section-title">
              <h2 className="hover-link">
                <span>
                  <a href="#testimonial">
                    <img src={link} alt="" />
                  </a>
                  Testimonials
                </span>
              </h2>
              <div className="bar"></div>
              <p>
                There is a reason why we are recognized as top developers, we
                deliver...
              </p>
            </div>
            <OwlCarousel
              className="owl-carousel owl-theme"
              {...testimonialCarouselOptions}
            >
              {testimonials &&
                testimonials.length > 0 &&
                testimonials.map((list: any) => (
                  <Testimonial data={list?.childMdx?.frontmatter} />
                ))}
            </OwlCarousel>
          </div>
        </section>

        <section className="clients pt-80 pb-50 bg-f7fafd" id="clients">
          <div className="container">
            <div className="section-title">
              <h2 className="hover-link">
                <span>
                  <a href="#clients">
                    <img src={link} alt="" />
                  </a>
                  Clients
                </span>
              </h2>
              <div className="bar"></div>
              <p>
                We pride ourselves to work with business who are market leaders
                of their industries
              </p>
            </div>
            {clients && clients.length > 0 && (
              <OwlCarousel
                className="owl-carousel owl-theme"
                {...clientsCarouselOptions}
              >
                {clients.map((client: any) => (
                  <div className="single-services-box client-list">
                    <div className="icon" style={{ background: "none" }}>
                      <img
                        src={client?.childMdx?.frontmatter?.logo?.publicURL}
                        alt=""
                      />
                    </div>
                    <h3>
                      <p className=" text-dark lh-1">
                        {" "}
                        {client?.childMdx?.frontmatter?.title}
                      </p>
                      <p className="badge rounded-pill industry-badge">
                        {client?.childMdx?.frontmatter?.industry}
                      </p>
                      <span>
                        {client?.childMdx?.frontmatter?.size},{" "}
                        {client?.childMdx?.frontmatter?.location}
                      </span>
                    </h3>
                    <MDXRenderer>{client?.childMdx?.body}</MDXRenderer>
                  </div>
                ))}
              </OwlCarousel>
            )}
          </div>
        </section>

        <section className="awards-recognition ptb-80" id="awards-recognition">
          <div className="container">
            <div className="section-title">
              <h2 className="hover-link">
                <span>
                  <a href="#awards-recognition">
                    <img src={link} alt="" />
                  </a>
                  Awards and Recognition
                </span>
              </h2>
              <div className="bar"></div>
              <p>We are proud to be recognized for quality of our work</p>
            </div>

            <OwlCarousel
              className="owl-carousel owl-theme"
              {...awardsCarouselOptions}
            >
              {awards &&
                awards.length > 0 &&
                awards.map((list: any) => (
                  <div className="awards-wrapper">
                    <h3>{list?.childMdx?.frontmatter.title}</h3>
                    <div
                      className={`img-wrapper ${
                        list?.childMdx?.frontmatter?.imageSize === "big"
                          ? "big"
                          : ""
                      }`}
                    >
                      <a
                        href={list?.childMdx?.frontmatter.link}
                        target="_blank"
                        style={{ width: "100%" }}
                      >
                        <img
                          src={list?.childMdx?.frontmatter.preview?.publicURL}
                          alt={list?.childMdx?.frontmatter.name}
                        />
                      </a>
                    </div>
                  </div>
                ))}
            </OwlCarousel>
          </div>
        </section>

        <GetInTouch />
      </main>
      <Footer />
    </Layout>
  )
}

export default IndexPage
export const query = graphql`
  query homeDetails {
    caseStudies: allFile(
      filter: {
        sourceInstanceName: { eq: "caseStudies" }
        name: { eq: "index" }
      }
    ) {
      nodes {
        childMdx {
          frontmatter {
            title
            thumbImg {
              publicURL
            }
            thumbImgAlt
            summary
          }
          slug
        }
        sourceInstanceName
        relativeDirectory
        name
      }
    }
    testimonials: allFile(
      filter: {
        sourceInstanceName: { eq: "testimonials" }
        name: { eq: "index" }
      }
      sort: { fields: childrenMdx___frontmatter___order }
    ) {
      nodes {
        childMdx {
          frontmatter {
            client
            reviewerDesignation
            title
            summary
            preview {
              publicURL
            }
            link
          }
        }
        relativeDirectory
        name
      }
    }

    clients: allFile(
      filter: { sourceInstanceName: { eq: "clients" }, name: { eq: "index" } }
    ) {
      nodes {
        childMdx {
          frontmatter {
            title
            size
            industry
            logo {
              publicURL
            }
            location
          }
          body
        }
        relativeDirectory
      }
    }
    awards: allFile(
      filter: { sourceInstanceName: { eq: "awards" }, name: { eq: "index" } }
      sort: { fields: childrenMdx___frontmatter___order }
    ) {
      nodes {
        childMdx {
          frontmatter {
            name
            title
            order
            link
            preview {
              publicURL
            }
            imageSize
          }
        }
        relativeDirectory
        name
      }
    }
    ourRecipeFeatures: allFile(
      filter: {
        sourceInstanceName: { eq: "ourRecipeFeatures" }
        name: { eq: "index" }
      }
      sort: { order: ASC, fields: childrenMdx___frontmatter___order }
    ) {
      nodes {
        childMdx {
          frontmatter {
            key
            title
            thumbImg {
              publicURL
            }
            thumbImgAlt
            order
          }
          body
        }
      }
    }
    featuredCaseStudy: site {
      siteMetadata {
        homeFeaturedCaseStudies
      }
    }
  }
`
